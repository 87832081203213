.mogelijkheden {
    height: 100%;
    background-color: var(--thuiskok-rood);
    color: white;
}

.mogelijkheid {
    text-align: left;
}

.mogelijkheden-container {
    max-width: 90% !important;
    text-align: left;
}

.mogelijkheden-container .row {
    margin-top: 30px;
}

@media (min-width: 992px) {
    .small-screen {
        display: none;
    }
}


@media (max-width: 992px) {
    .mogelijkheden-container .big-screen {
        display: none;
    }

    .small-screen {
        display: block;
    }
}