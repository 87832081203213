.contact-container {
    color: white;
    background-color: var(--thuiskok-rood);
}

.contactgegevens {
    text-align: left;
    font-size: 1.3em;
}

.social-media-img {
    height: 1.3em;
    width: auto;
    display: inline;
    margin-right: 20px;
}

.small-screen {
    display: none;
}

@media (min-width: 992px) {
    .social-media {
        position: absolute;
        right: 0;
        bottom: 1.3em;
    }
}

@media (max-width: 992px) {
    .contactgegevens {
        font-size: 1em;
    }

    .contact-container .big-screen {
        display: none;
    }

    .small-screen {
        display: inline-block
    }
}