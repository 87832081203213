.gerecht {
    color: var(--thuiskok-rood)
}

.gerechten-container {
    max-width: 90% !important;
}

.gerechten-image {
    width: 90%;
    height: auto;
    margin-bottom: 50px;
}