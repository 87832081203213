.nav-link{
    color: var(--thuiskok-rood) !important;
}

.navbar {
    opacity: 0.9;
}

.nav-link:hover {
    color: #353535 !important;
}

.navbar-collapse {
    justify-content: center;
}

.navbar img {
    height: 30px;
    width: auto;
}

.navbar-toggler {
    border: none !important;
}