.home {
    background-image: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../../assets/1-Thuiskok-openingsfoto.jpg');
    background-size: cover;
    height: 100vh;
}

img {
    height: 130px;
}

.mobile {
    display: none;
}
.text {
    height: 50px !important;
    position: relative;
    top: 43px;
}

.logo {
    text-align: left;
    align-items: center;
    display: block;
    justify-content: center;
    width: 70%;
    margin: auto;
}

.logo-container {
    margin-top: 28vh;
}

.bullets {
    margin: auto;
    margin-top: 1em;
    color: white;
}

.contact-button {
    display: block;
    color: white;
    background-color: var(--thuiskok-rood);
    padding: 20px 50px 20px 50px;
    border-radius: 1em;
    width: 300px;
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
    text-decoration: none;
}

.contact-button:hover {
    color: white;
    text-decoration: none;
}

.contact-button p {
    margin-bottom: 0;
}


.bullets {
    font-size: 1.8em;
    font-family: 'American Typewriter' !important;
}

@media (max-width: 1200px) {
    img {
        height: 90px;
    }

    .text {
        height: 40px !important;
        position: relative;
        top: 27px;
    }

    .bullets {
        font-size: 1.3em;
    }
}

@media (max-width: 992px) {
    img {
        height: 65px;
    }

    .text {
        height: 30px !important;
        position: relative;
        top: 20px;
    }

    .bullets {
        font-size: 1.1em;
    }
}

@media (max-width: 768px) {
    .logo {
        text-align: center;
        width: 95%;
    }

    .mobile {
        display: block;
    }

    .text {
        height: 23px !important;
        position: relative;
        top: 20px;
    }

    .bullets {
        margin-top: 35px;
        font-size: 1em;
    }

    .contact-button {
        display: inline-block;
        padding: 15px 37.5px 15px 37.5px;
        border-radius: 0.8em;
        width: 225px;
        text-align: center;
    }


    .logo-container {
        margin-top: 20vh;
    }
}