.culinaire-verwennerij-container {
    height: 100vh;
    background-image: url('../../assets/6_foto-tafel.jpg');
    background-size: cover;
    text-align: center;
}

.image-overlay {
    padding-top: 55vh;
}

.tekst-image {
    width: 85%;
    height: auto;
}

.of-thuis-image {
    margin-top: 10px;
    height: auto;
    width: 30%;
    margin-left: 5%;
}

@media (max-width: 992px) {
    .culinaire-verwennerij-container {
        height: 50vh;
    }

    .image-overlay {
        padding-top: 25vh;
    }    
}

@media (max-width: 768px) {
    .culinaire-verwennerij-container {
        height: 33vh;
    }

    .image-overlay {
        padding-top: 17vh;
    }    
}