.weblinks-container {
    color: white;
    background-image: url('../../assets/8-achtergr-groen.jpg');
    background-size: cover;
}

.weblinks-tabel-container {
    max-width: 90% !important;
}

.weblinks-container img {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.weblinks-container a {
    text-decoration: none;
    color: white;
}

.weblinks-container a:hover {
    color: white;
}

@media(max-width: 768px) {
    .weblinks-container img {
        width: 70%;
    }

    .weblinks-container .row {
        text-align: center;
    }
}