.zalf-naar-saus {
    color: var(--thuiskok-rood);
}

.over-mij-image {
    width: 100%;
    height: auto;
}

.over-mij-image-col {
    margin: auto;
}