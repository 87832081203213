@import url(//db.onlinewebfonts.com/c/bf35ddaa105b41dd66cb2eb93424c588?family=American+Typewriter);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --thuiskok-rood: rgb(205, 0, 58);
  --thuiskok-groen: rgb(102, 178, 87);
  scroll-behavior: smooth;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

header {
  font-family: 'American Typewriter' !important;
  font-size: 48px;
}

.menu {
  font-size: 18px;
}

.sub-header {
  font-family: 'American Typewriter' !important;
  font-size: 70px;
}

b {
  font-weight: 900;
}

.intro {
  font-size: 1.4em;
}

p {
  font-size: 1.1em;
  font-family: Arial, Helvetica, sans-serif;
}

.p-header {
  font-family: 'American Typewriter' !important;
  font-size: 1.8em;
  margin-top: 0;
}

.page-container {
  padding-top: 4em;
  padding-bottom: 4em;
  text-align: center;
}

.header-red {
  color: rgb(205, 0, 58);
  color: var(--thuiskok-rood);
}

@media (max-width: 768px) {
  .intro {
    font-size: 1.2em;
  }

  .big-screen {
    display: none;
  }
}

@media (max-width: 992px) {
  p {
    font-size: 1em;
  }

  .row {
    margin-top: 15px !important;
  }

  .page-container {
    padding-top: 2em;
  }
}
.home {
    background-image: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(/static/media/1-Thuiskok-openingsfoto.0609c3d8.jpg);
    background-size: cover;
    height: 100vh;
}

img {
    height: 130px;
}

.mobile {
    display: none;
}
.text {
    height: 50px !important;
    position: relative;
    top: 43px;
}

.logo {
    text-align: left;
    align-items: center;
    display: block;
    justify-content: center;
    width: 70%;
    margin: auto;
}

.logo-container {
    margin-top: 28vh;
}

.bullets {
    margin: auto;
    margin-top: 1em;
    color: white;
}

.contact-button {
    display: block;
    color: white;
    background-color: var(--thuiskok-rood);
    padding: 20px 50px 20px 50px;
    border-radius: 1em;
    width: 300px;
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
    text-decoration: none;
}

.contact-button:hover {
    color: white;
    text-decoration: none;
}

.contact-button p {
    margin-bottom: 0;
}


.bullets {
    font-size: 1.8em;
    font-family: 'American Typewriter' !important;
}

@media (max-width: 1200px) {
    img {
        height: 90px;
    }

    .text {
        height: 40px !important;
        position: relative;
        top: 27px;
    }

    .bullets {
        font-size: 1.3em;
    }
}

@media (max-width: 992px) {
    img {
        height: 65px;
    }

    .text {
        height: 30px !important;
        position: relative;
        top: 20px;
    }

    .bullets {
        font-size: 1.1em;
    }
}

@media (max-width: 768px) {
    .logo {
        text-align: center;
        width: 95%;
    }

    .mobile {
        display: block;
    }

    .text {
        height: 23px !important;
        position: relative;
        top: 20px;
    }

    .bullets {
        margin-top: 35px;
        font-size: 1em;
    }

    .contact-button {
        display: inline-block;
        padding: 15px 37.5px 15px 37.5px;
        border-radius: 0.8em;
        width: 225px;
        text-align: center;
    }


    .logo-container {
        margin-top: 20vh;
    }
}
.site-intro {
    height: 100%;
}

.intro-container {
    width: 80%;
}
.mogelijkheden {
    height: 100%;
    background-color: var(--thuiskok-rood);
    color: white;
}

.mogelijkheid {
    text-align: left;
}

.mogelijkheden-container {
    max-width: 90% !important;
    text-align: left;
}

.mogelijkheden-container .row {
    margin-top: 30px;
}

@media (min-width: 992px) {
    .small-screen {
        display: none;
    }
}


@media (max-width: 992px) {
    .mogelijkheden-container .big-screen {
        display: none;
    }

    .small-screen {
        display: block;
    }
}
.page-image {
    width: 100%;
    height: auto;
}
.gerecht {
    color: var(--thuiskok-rood)
}

.gerechten-container {
    max-width: 90% !important;
}

.gerechten-image {
    width: 90%;
    height: auto;
    margin-bottom: 50px;
}
.culinaire-verwennerij-container {
    height: 100vh;
    background-image: url(/static/media/6_foto-tafel.3f5f3393.jpg);
    background-size: cover;
    text-align: center;
}

.image-overlay {
    padding-top: 55vh;
}

.tekst-image {
    width: 85%;
    height: auto;
}

.of-thuis-image {
    margin-top: 10px;
    height: auto;
    width: 30%;
    margin-left: 5%;
}

@media (max-width: 992px) {
    .culinaire-verwennerij-container {
        height: 50vh;
    }

    .image-overlay {
        padding-top: 25vh;
    }    
}

@media (max-width: 768px) {
    .culinaire-verwennerij-container {
        height: 33vh;
    }

    .image-overlay {
        padding-top: 17vh;
    }    
}
.zalf-naar-saus {
    color: var(--thuiskok-rood);
}

.over-mij-image {
    width: 100%;
    height: auto;
}

.over-mij-image-col {
    margin: auto;
}
.weblinks-container {
    color: white;
    background-image: url(/static/media/8-achtergr-groen.99875366.jpg);
    background-size: cover;
}

.weblinks-tabel-container {
    max-width: 90% !important;
}

.weblinks-container img {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.weblinks-container a {
    text-decoration: none;
    color: white;
}

.weblinks-container a:hover {
    color: white;
}

@media(max-width: 768px) {
    .weblinks-container img {
        width: 70%;
    }

    .weblinks-container .row {
        text-align: center;
    }
}
.contact-container {
    color: white;
    background-color: var(--thuiskok-rood);
}

.contactgegevens {
    text-align: left;
    font-size: 1.3em;
}

.social-media-img {
    height: 1.3em;
    width: auto;
    display: inline;
    margin-right: 20px;
}

.small-screen {
    display: none;
}

@media (min-width: 992px) {
    .social-media {
        position: absolute;
        right: 0;
        bottom: 1.3em;
    }
}

@media (max-width: 992px) {
    .contactgegevens {
        font-size: 1em;
    }

    .contact-container .big-screen {
        display: none;
    }

    .small-screen {
        display: inline-block
    }
}
.footer {
    color: white;
    background-color: var(--thuiskok-rood);
    text-align: center;
}

.luc {
    font-size: 0.7em;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.copyright {
    font-size: 0.9em;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.nav-link{
    color: var(--thuiskok-rood) !important;
}

.navbar {
    opacity: 0.9;
}

.nav-link:hover {
    color: #353535 !important;
}

.navbar-collapse {
    justify-content: center;
}

.navbar img {
    height: 30px;
    width: auto;
}

.navbar-toggler {
    border: none !important;
}
