@import url(//db.onlinewebfonts.com/c/bf35ddaa105b41dd66cb2eb93424c588?family=American+Typewriter);

:root {
  --thuiskok-rood: rgb(205, 0, 58);
  --thuiskok-groen: rgb(102, 178, 87);
  scroll-behavior: smooth;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

header {
  font-family: 'American Typewriter' !important;
  font-size: 48px;
}

.menu {
  font-size: 18px;
}

.sub-header {
  font-family: 'American Typewriter' !important;
  font-size: 70px;
}

b {
  font-weight: 900;
}

.intro {
  font-size: 1.4em;
}

p {
  font-size: 1.1em;
  font-family: Arial, Helvetica, sans-serif;
}

.p-header {
  font-family: 'American Typewriter' !important;
  font-size: 1.8em;
  margin-top: 0;
}

.page-container {
  padding-top: 4em;
  padding-bottom: 4em;
  text-align: center;
}

.header-red {
  color: var(--thuiskok-rood);
}

@media (max-width: 768px) {
  .intro {
    font-size: 1.2em;
  }

  .big-screen {
    display: none;
  }
}

@media (max-width: 992px) {
  p {
    font-size: 1em;
  }

  .row {
    margin-top: 15px !important;
  }

  .page-container {
    padding-top: 2em;
  }
}